<template>
  <page :title="title">
    <b-container>
      <b-row class="justify-content-center">
        <!-- <b-col v-if="test === 'jumbo'" cols="12">
          <b-jumbotron>
            <b-row>
              <b-col cols="12" md="6"
                ><p class="lead mb-4">
                  Mirror:
                  <i
                    >“Which offers the image of something and somehow puts it in
                    front of our eyes”</i
                  >
                </p> </b-col
              ><b-col cols="12" md="6">
                <p>A Pair of glasses is the mirror of yourself,</p>
                <p>The mirror of what you feel,</p>
                <p>The mirror of who you are,</p>
                <p>Want to be</p>
                <p>Want to show</p></b-col
              >
            </b-row>
          </b-jumbotron>
        </b-col> -->
        <b-col class="team-member mb-4" cols="12" md="9" lg="6">
          <b-img
            thumbnail
            fluid
            rounded="circle"
            src="@/assets/media/monmiroir.jpg"
          ></b-img>
          <h3 class="mt-3 mb-3">{{ branding.MONMIROIR }}</h3>
          <!-- <template v-if="test === 'left'">
            <p>
              Mirror:
              <i
                >“Which offers the image of something and somehow puts it in
                front of our eyes”</i
              >
            </p>
            <p>A Pair of glasses is the mirror of yourself,</p>
            <p>The mirror of what you feel,</p>
            <p>The mirror of who you are,</p>
            <p>Want to be</p>
            <p>Want to show</p>
          </template> -->
          <p>
            “{{ branding.MONMIROIR }}” is a Californian-French eyewear concept launched by
            Samy Bensbaa. It was generated by the idea that a pair of glasses is
            a central and significant accessory to express personality and
            sensibility. It's the reflection of who you feel you are.
          </p>
          <p>
            Each “{{ branding.MONMIROIR }}” pair of glasses is designed and handmade by Samy
            Bensbaa himself in the San Francisco Bay Area based on his unique
            designs, meticulous hand-making and high-quality and
            cultural-imprinted material. Collections vary from very small and
            confidential series to unique individually conceived pairs.
          </p>
        </b-col>
        <b-col class="team-member mb-4" sm="12" md="9" lg="6">
          <b-img
            thumbnail
            fluid
            rounded="circle"
            src="@/assets/media/samy.jpg"
          ></b-img>
          <h3 class="mt-3 mb-3">Samy Bensbaa</h3>
          <p>
            Aesthetic, Details, Precision, Passion and Love are Samy Bensbaa’s
            drivers to create, innovate and create again.
          </p>
          <p>
            Samy Bensbaa is an Optometry Doctor and self-made eyewear designer
            and crafter. Shapes, designs and materials he uses are inspired by
            materials, faces, personalities and experiences.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </page>
</template>

<script>
import Page from "./Page.vue";
import { mapState } from 'vuex';
// import InstaEmbed from "../components/InstaEmbed.vue";
export default {
  props: ["test"],
  data() {
    return {
      title: "about",
    };
  },
  computed: {
    ...mapState([ "branding" ]),
  },
  components: {
    Page,
    // InstaEmbed
  },
};
</script>

<style lang="scss" scoped>
.lead {
  font-size: 1.5rem;
  font-weight: 400;
}
.jumbotron p {
  margin-bottom: 0.2rem;
}
.team-member img {
  width: 50%;
}

.team-member {
  text-align: center;
}
</style>
